h1, h2, h3, h4, p, span{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    line-height: 48px;
    font-weight: 400;
    font-style: normal;
    color: #B5B5B5;
}
p{
    font-size: 20px;
}
image{
    align-self: center;
}
ul{
    list-style-type: none;
    flex-direction: row;

}
li{
    margin-left: 10px;
    color: white;
}
.activeMenu{
    color: #89049F;

}